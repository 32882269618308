var render = function () {
  var _vm$item$accountId, _vm$item$address, _vm$item$what3Words, _vm$item$transport, _vm$item$transport2, _vm$item$types, _vm$item$disciplines, _vm$item$activities, _vm$item$childActivit, _vm$borough$fields, _vm$borough$fields2, _vm$item$transport3, _vm$item$transport3$z, _vm$item$transport4, _vm$item$transport4$b, _vm$item$transport5, _vm$item$transport5$r, _vm$item$transport6, _vm$item$transport6$t, _vm$item$transport7, _vm$item$transport7$n, _vm$item$transport8, _vm$item$transport8$c, _vm$item$transport9, _vm$item$transport9$b, _vm$item$transport10, _vm$item$transport10$, _vm$item$what3Words2, _vm$item$address2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic info",
      "item": _vm.item,
      "tab": 0
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Public URL ")]), _c('v-list-item-subtitle', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "".concat(_vm.artistURL, "/").concat(_vm.item.accountId.slug, "/").concat(_vm.item.slug)
    }
  }, [_vm._v(" " + _vm._s("".concat(_vm.artistURL, "/").concat(_vm.item.accountId.slug, "/").concat(_vm.item.slug)) + " ")])])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Name")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.name) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toView('account', _vm.item.accountId._id)
    }
  }, [_c('v-list-item-title', [_vm._v("Host profile")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$accountId = _vm.item.accountId) === null || _vm$item$accountId === void 0 ? void 0 : _vm$item$accountId.name) + " ")])], 1), _vm.item.address ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Postcode")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$address = _vm.item.address) === null || _vm$item$address === void 0 ? void 0 : _vm$item$address.postcode) + " ")])], 1) : _vm._e(), (_vm$item$what3Words = _vm.item.what3Words) !== null && _vm$item$what3Words !== void 0 && _vm$item$what3Words.words ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("What3Words")]), _c('v-list-item-subtitle', [_c('dtWhat3Words', {
    attrs: {
      "value": _vm.item.what3Words.words
    }
  })], 1)], 1) : _vm._e(), (_vm$item$transport = _vm.item.transport) !== null && _vm$item$transport !== void 0 && _vm$item$transport.borough ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Borough")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$transport2 = _vm.item.transport) === null || _vm$item$transport2 === void 0 ? void 0 : _vm$item$transport2.borough) + " ")])], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Status")]), _c('v-list-item-subtitle', [_c('dt-venue-status', {
    attrs: {
      "value": _vm.item.status
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Visibility")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.item.visibility)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Marketplace commission ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.commission) + "% ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Partners commission ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.manualPaymentCommission) + "% ")])], 1)], 1), _c('vx-card-list-meta', {
    attrs: {
      "item": _vm.item
    }
  }), (_vm$item$types = _vm.item.types) !== null && _vm$item$types !== void 0 && _vm$item$types.length ? _c('vx-card-list', {
    attrs: {
      "title": "Location Types",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.types, function (type, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "to": _vm.$toEdit('attribute', type.attributeId)
      }
    }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(type.attributeName) + " ")])], 1);
  }), 1) : _vm._e(), (_vm$item$disciplines = _vm.item.disciplines) !== null && _vm$item$disciplines !== void 0 && _vm$item$disciplines.length ? _c('vx-card-list', {
    attrs: {
      "title": "Disciplines",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.disciplines, function (type, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "to": _vm.$toEdit('attribute', type.attributeId)
      }
    }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(type.attributeName) + " ")])], 1);
  }), 1) : _vm._e(), (_vm$item$activities = _vm.item.activities) !== null && _vm$item$activities !== void 0 && _vm$item$activities.length ? _c('vx-card-list', {
    attrs: {
      "title": "Activities",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.activities, function (type, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "to": _vm.$toEdit('attribute', type.attributeId)
      }
    }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(type.attributeName) + " ")])], 1);
  }), 1) : _vm._e(), (_vm$item$childActivit = _vm.item.childActivities) !== null && _vm$item$childActivit !== void 0 && _vm$item$childActivit.length ? _c('vx-card-list', {
    attrs: {
      "title": "Child activities",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.childActivities, function (type, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "to": _vm.$toEdit('attribute', type.attributeId)
      }
    }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(type.attributeName) + " ")])], 1);
  }), 1) : _vm._e(), _vm.item.transport ? [_vm.item.transport.borough && _vm.borough ? _c('vx-card-list', {
    attrs: {
      "title": "Borough Film Services Contacts",
      "item": _vm.borough,
      "route-name": "collection"
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v("Name")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.borough.name) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Company")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$borough$fields = _vm.borough.fields) === null || _vm$borough$fields === void 0 ? void 0 : _vm$borough$fields.company) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Website")]), _c('v-list-item-subtitle', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.borough.fields.website
    }
  }, [_vm._v(_vm._s((_vm$borough$fields2 = _vm.borough.fields) === null || _vm$borough$fields2 === void 0 ? void 0 : _vm$borough$fields2.website))])])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Email")]), _c('v-list-item-subtitle', _vm._l(_vm.borough.fields.emails, function (email, index) {
    return _c('a', {
      key: index,
      staticClass: "d-block",
      attrs: {
        "href": "mailto:".concat(email)
      }
    }, [_vm._v(" " + _vm._s(email) + " ")]);
  }), 0)], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Phone")]), _c('v-list-item-subtitle', _vm._l(_vm.borough.fields.phones, function (phone, index) {
    return _c('a', {
      key: index,
      staticClass: "d-block",
      attrs: {
        "href": "tel:".concat(phone)
      }
    }, [_vm._v(" " + _vm._s(phone) + " ")]);
  }), 0)], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Contact")]), _c('v-list-item-subtitle', _vm._l(_vm.borough.fields.contacts, function (contact, index) {
    return _c('div', {
      key: index
    }, [_vm._v(_vm._s(contact))]);
  }), 0)], 1)], 1) : _vm._e(), (_vm$item$transport3 = _vm.item.transport) !== null && _vm$item$transport3 !== void 0 && (_vm$item$transport3$z = _vm$item$transport3.zones) !== null && _vm$item$transport3$z !== void 0 && _vm$item$transport3$z.length ? _c('vx-card-list', {
    attrs: {
      "title": "Zones",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-row', _vm._l(_vm.item.transport.zones, function (value, key) {
    return _c('v-col', {
      key: key,
      attrs: {
        "cols": "6"
      }
    }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(_vm._s(value.name))])], 1)], 1);
  }), 1)], 1) : _vm._e(), (_vm$item$transport4 = _vm.item.transport) !== null && _vm$item$transport4 !== void 0 && (_vm$item$transport4$b = _vm$item$transport4.buses) !== null && _vm$item$transport4$b !== void 0 && _vm$item$transport4$b.length ? _c('vx-card-list', {
    attrs: {
      "title": "Buses",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-row', _vm._l(_vm.item.transport.buses, function (value, key) {
    return _c('v-col', {
      key: key,
      attrs: {
        "cols": "2"
      }
    }, [_c('v-list-item', {
      attrs: {
        "target": "_blank",
        "href": "".concat(_vm.TRANSPORT_LINK.BUS, "/").concat(value.slug)
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(value.name))])], 1)], 1);
  }), 1)], 1) : _vm._e(), (_vm$item$transport5 = _vm.item.transport) !== null && _vm$item$transport5 !== void 0 && (_vm$item$transport5$r = _vm$item$transport5.riverBuses) !== null && _vm$item$transport5$r !== void 0 && _vm$item$transport5$r.length ? _c('vx-card-list', {
    attrs: {
      "title": "River buses",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-row', _vm._l(_vm.item.transport.riverBuses, function (value, key) {
    return _c('v-col', {
      key: key,
      attrs: {
        "cols": "6"
      }
    }, [_c('v-list-item', {
      attrs: {
        "target": "_blank",
        "href": "".concat(_vm.TRANSPORT_LINK.RIVER_BUS, "/").concat(value.slug)
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(value.name))])], 1)], 1);
  }), 1)], 1) : _vm._e(), (_vm$item$transport6 = _vm.item.transport) !== null && _vm$item$transport6 !== void 0 && (_vm$item$transport6$t = _vm$item$transport6.tubes) !== null && _vm$item$transport6$t !== void 0 && _vm$item$transport6$t.length ? _c('vx-card-list', {
    attrs: {
      "title": "Tubes",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-row', _vm._l(_vm.item.transport.tubes, function (value, key) {
    return _c('v-col', {
      key: key,
      attrs: {
        "cols": "4"
      }
    }, [_c('v-list-item', {
      attrs: {
        "target": "_blank",
        "href": "".concat(_vm.TRANSPORT_LINK.TUBE, "/").concat(value.slug)
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(value.name))])], 1)], 1);
  }), 1)], 1) : _vm._e(), (_vm$item$transport7 = _vm.item.transport) !== null && _vm$item$transport7 !== void 0 && (_vm$item$transport7$n = _vm$item$transport7.nationalRails) !== null && _vm$item$transport7$n !== void 0 && _vm$item$transport7$n.length ? _c('vx-card-list', {
    attrs: {
      "title": "National rails",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-row', _vm._l(_vm.item.transport.nationalRails, function (value, key) {
    return _c('v-col', {
      key: key,
      attrs: {
        "cols": "6"
      }
    }, [_c('v-list-item', {
      attrs: {
        "target": "_blank",
        "href": "".concat(_vm.TRANSPORT_LINK.NATIONAL_RAIL, "/").concat(value.slug)
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(value.name))])], 1)], 1);
  }), 1)], 1) : _vm._e(), (_vm$item$transport8 = _vm.item.transport) !== null && _vm$item$transport8 !== void 0 && (_vm$item$transport8$c = _vm$item$transport8.cycleParks) !== null && _vm$item$transport8$c !== void 0 && _vm$item$transport8$c.length ? _c('vx-card-list', {
    attrs: {
      "title": "Cycle parks",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v("Name")]), _c('v-list-item-title', {
    staticClass: "text-center"
  }, [_vm._v("Distance")])], 1), _c('v-divider'), _vm._l(_vm.item.transport.cycleParks, function (value, key) {
    return _c('v-list-item', {
      key: key
    }, [_c('v-list-item-title', [_vm._v(_vm._s(value.name))]), _c('v-list-item-subtitle', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm._f("distance")(value.distance)))])], 1);
  })], 2) : _vm._e(), (_vm$item$transport9 = _vm.item.transport) !== null && _vm$item$transport9 !== void 0 && (_vm$item$transport9$b = _vm$item$transport9.bikePoints) !== null && _vm$item$transport9$b !== void 0 && _vm$item$transport9$b.length ? _c('vx-card-list', {
    attrs: {
      "title": "Bike points",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v("Name")]), _c('v-list-item-title', {
    staticClass: "text-center"
  }, [_vm._v("Distance")]), _c('v-list-item-title', {
    staticClass: "text-center"
  }, [_vm._v("Docks")])], 1), _c('v-divider'), _vm._l(_vm.item.transport.bikePoints, function (value, key) {
    return _c('v-list-item', {
      key: key
    }, [_c('v-list-item-title', {
      attrs: {
        "title": value.name
      }
    }, [_vm._v(_vm._s(value.name))]), _c('v-list-item-subtitle', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm._f("distance")(value.distance)))]), _c('v-list-item-subtitle', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(value.docks))])], 1);
  })], 2) : _vm._e(), (_vm$item$transport10 = _vm.item.transport) !== null && _vm$item$transport10 !== void 0 && (_vm$item$transport10$ = _vm$item$transport10.others) !== null && _vm$item$transport10$ !== void 0 && _vm$item$transport10$.length ? _c('vx-card-list', {
    attrs: {
      "title": "Other transportation",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-row', _vm._l(_vm.item.transport.others, function (value, key) {
    return _c('v-col', {
      key: key,
      attrs: {
        "cols": "6"
      }
    }, [_c('v-list-item', {
      attrs: {
        "target": "_blank",
        "href": "".concat(_vm.TRANSPORT_LINK[value.slug], "/").concat(value.slug)
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(value.name))])], 1)], 1);
  }), 1)], 1) : _vm._e()] : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "References",
      "hide-edit": "",
      "item": _vm.item
    }
  }, [_c('v-list-item', {
    attrs: {
      "to": _vm.$toList('location', {
        accountId: _vm.item.accountId._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" This host’s locations ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.accountLocations) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('space', {
        locationId: this.$route.params.id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" All spaces ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.spaces) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('space', {
        locationId: this.$route.params.id,
        status: 0
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Drafted spaces ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.draftedSpaces) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('space', {
        locationId: this.$route.params.id,
        status: 'open'
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Open spaces ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.openSpaces) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('space', {
        locationId: this.$route.params.id,
        status: 'closed'
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Closed spaces ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.closedSpaces) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('booking', {
        locationId: this.$route.params.id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Bookings")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.bookings || 0) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('message', {
        locationId: this.$route.params.id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Messages")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.messages || 0) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('review', {
        locationId: this.$route.params.id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Reviews")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.reviews || 0) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('account', {
        favorite: this.$route.params.id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Favourites")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.wishlists || 0) + " ")])], 1)], 1), _c('vx-card-list', {
    attrs: {
      "title": "Availability",
      "item": _vm.item,
      "tab": 1
    }
  }, _vm._l(_vm.item.availabilities, function (availability, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(availability.day) + " ")]), _c('v-list-item-subtitle', [availability.enabled ? [availability.all ? [_vm._v("24 hour")] : _vm._l(availability.slots, function (slot, slotIndex) {
      return [_c('div', {
        key: slotIndex
      }, [_vm._v(_vm._s(_vm._f("hour24")(slot.startHour)) + " - " + _vm._s(_vm._f("hour24")(slot.endHour)))])];
    })] : [_vm._v("Closed")]], 2)], 1);
  }), 1), (_vm$item$what3Words2 = _vm.item.what3Words) !== null && _vm$item$what3Words2 !== void 0 && _vm$item$what3Words2.words ? _c('vx-card-list', {
    attrs: {
      "title": "What3Words Address",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.what3Words, function (value, key) {
    return _c('v-list-item', {
      key: key
    }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm._f("capitalize")(key)))]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(value) + " ")])], 1);
  }), 1) : _vm._e(), (_vm$item$address2 = _vm.item.address) !== null && _vm$item$address2 !== void 0 && _vm$item$address2.postcode ? _c('vx-card-list', {
    attrs: {
      "title": "Ideal Postcodes Address",
      "item": _vm.item,
      "tab": 0
    }
  }, [_vm._l(_vm.item.address, function (value, key) {
    return [value ? _c('v-list-item', {
      key: key
    }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm._f("capitalize")(key)))]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(value) + " ")])], 1) : _vm._e()];
  })], 2) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-card-json', {
    attrs: {
      "item": _vm.item
    }
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }