<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="6">
        <vx-card-list title="Basic info" :item="item" :tab="0">
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Public URL </v-list-item-title>
            <v-list-item-subtitle>
              <a target="_blank" :href="`${artistURL}/${item.accountId.slug}/${item.slug}`">
                {{ `${artistURL}/${item.accountId.slug}/${item.slug}` }}
              </a>
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Name</v-list-item-title>
            <v-list-item-subtitle> {{ item.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toView('account', item.accountId._id)">
            <v-list-item-title>Host profile</v-list-item-title>
            <v-list-item-subtitle> {{ item.accountId?.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.address">
            <v-list-item-title>Postcode</v-list-item-title>
            <v-list-item-subtitle> {{ item.address?.postcode }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.what3Words?.words">
            <v-list-item-title>What3Words</v-list-item-title>
            <v-list-item-subtitle>
              <dtWhat3Words :value="item.what3Words.words" />
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.transport?.borough">
            <v-list-item-title>Borough</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.transport?.borough }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Status</v-list-item-title>
            <v-list-item-subtitle> <dt-venue-status :value="item.status" /> </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Visibility</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.visibility | capitalize }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Marketplace commission </v-list-item-title>
            <v-list-item-subtitle>{{ item.commission }}% </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title> Partners commission </v-list-item-title>
            <v-list-item-subtitle>{{ item.manualPaymentCommission }}% </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list-meta :item="item" />

        <vx-card-list v-if="item.types?.length" title="Location Types" :item="item" :tab="0">
          <v-list-item v-for="(type, index) in item.types" :key="index" :to="$toEdit('attribute', type.attributeId)">
            <v-list-item-subtitle> {{ type.attributeName }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.disciplines?.length" title="Disciplines" :item="item" :tab="0">
          <v-list-item
            v-for="(type, index) in item.disciplines"
            :key="index"
            :to="$toEdit('attribute', type.attributeId)"
          >
            <v-list-item-subtitle> {{ type.attributeName }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.activities?.length" title="Activities" :item="item" :tab="0">
          <v-list-item
            v-for="(type, index) in item.activities"
            :key="index"
            :to="$toEdit('attribute', type.attributeId)"
          >
            <v-list-item-subtitle> {{ type.attributeName }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.childActivities?.length" title="Child activities" :item="item" :tab="0">
          <v-list-item
            v-for="(type, index) in item.childActivities"
            :key="index"
            :to="$toEdit('attribute', type.attributeId)"
          >
            <v-list-item-subtitle> {{ type.attributeName }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <template v-if="item.transport">
          <vx-card-list
            v-if="item.transport.borough && borough"
            title="Borough Film Services Contacts"
            :item="borough"
            route-name="collection"
          >
            <v-list-item>
              <v-list-item-title>Name</v-list-item-title>
              <v-list-item-subtitle> {{ borough.name }} </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Company</v-list-item-title>
              <v-list-item-subtitle> {{ borough.fields?.company }} </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Website</v-list-item-title>
              <v-list-item-subtitle>
                <a target="_blank" :href="borough.fields.website">{{ borough.fields?.website }}</a>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Email</v-list-item-title>
              <v-list-item-subtitle>
                <a
                  v-for="(email, index) in borough.fields.emails"
                  :key="index"
                  class="d-block"
                  :href="`mailto:${email}`"
                >
                  {{ email }}
                </a>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Phone</v-list-item-title>
              <v-list-item-subtitle>
                <a v-for="(phone, index) in borough.fields.phones" :key="index" class="d-block" :href="`tel:${phone}`">
                  {{ phone }}
                </a>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Contact</v-list-item-title>
              <v-list-item-subtitle>
                <div v-for="(contact, index) in borough.fields.contacts" :key="index">{{ contact }}</div>
              </v-list-item-subtitle>
            </v-list-item>
          </vx-card-list>

          <vx-card-list v-if="item.transport?.zones?.length" title="Zones" :item="item" hide-edit>
            <v-row>
              <v-col v-for="(value, key) in item.transport.zones" :key="key" cols="6">
                <v-list-item>
                  <v-list-item-title>{{ value.name }}</v-list-item-title>
                </v-list-item>
              </v-col>
            </v-row>
          </vx-card-list>

          <vx-card-list v-if="item.transport?.buses?.length" title="Buses" :item="item" hide-edit>
            <v-row>
              <v-col v-for="(value, key) in item.transport.buses" :key="key" cols="2">
                <v-list-item target="_blank" :href="`${TRANSPORT_LINK.BUS}/${value.slug}`">
                  <v-list-item-title>{{ value.name }}</v-list-item-title>
                </v-list-item>
              </v-col>
            </v-row>
          </vx-card-list>

          <vx-card-list v-if="item.transport?.riverBuses?.length" title="River buses" :item="item" hide-edit>
            <v-row>
              <v-col v-for="(value, key) in item.transport.riverBuses" :key="key" cols="6">
                <v-list-item target="_blank" :href="`${TRANSPORT_LINK.RIVER_BUS}/${value.slug}`">
                  <v-list-item-title>{{ value.name }}</v-list-item-title>
                </v-list-item>
              </v-col>
            </v-row>
          </vx-card-list>

          <vx-card-list v-if="item.transport?.tubes?.length" title="Tubes" :item="item" hide-edit>
            <v-row>
              <v-col v-for="(value, key) in item.transport.tubes" :key="key" cols="4">
                <v-list-item target="_blank" :href="`${TRANSPORT_LINK.TUBE}/${value.slug}`">
                  <v-list-item-title>{{ value.name }}</v-list-item-title>
                </v-list-item>
              </v-col>
            </v-row>
          </vx-card-list>

          <vx-card-list v-if="item.transport?.nationalRails?.length" title="National rails" :item="item" hide-edit>
            <v-row>
              <v-col v-for="(value, key) in item.transport.nationalRails" :key="key" cols="6">
                <v-list-item target="_blank" :href="`${TRANSPORT_LINK.NATIONAL_RAIL}/${value.slug}`">
                  <v-list-item-title>{{ value.name }}</v-list-item-title>
                </v-list-item>
              </v-col>
            </v-row>
          </vx-card-list>

          <vx-card-list v-if="item.transport?.cycleParks?.length" title="Cycle parks" :item="item" hide-edit>
            <v-list-item>
              <v-list-item-title>Name</v-list-item-title>
              <v-list-item-title class="text-center">Distance</v-list-item-title>
            </v-list-item>

            <v-divider />

            <v-list-item v-for="(value, key) in item.transport.cycleParks" :key="key">
              <v-list-item-title>{{ value.name }}</v-list-item-title>
              <v-list-item-subtitle class="text-center">{{ value.distance | distance }}</v-list-item-subtitle>
            </v-list-item>
          </vx-card-list>

          <vx-card-list v-if="item.transport?.bikePoints?.length" title="Bike points" :item="item" hide-edit>
            <v-list-item>
              <v-list-item-title>Name</v-list-item-title>
              <v-list-item-title class="text-center">Distance</v-list-item-title>
              <v-list-item-title class="text-center">Docks</v-list-item-title>
            </v-list-item>

            <v-divider />

            <v-list-item v-for="(value, key) in item.transport.bikePoints" :key="key">
              <v-list-item-title :title="value.name">{{ value.name }}</v-list-item-title>
              <v-list-item-subtitle class="text-center">{{ value.distance | distance }}</v-list-item-subtitle>
              <v-list-item-subtitle class="text-center">{{ value.docks }}</v-list-item-subtitle>
            </v-list-item>
          </vx-card-list>

          <vx-card-list v-if="item.transport?.others?.length" title="Other transportation" :item="item" hide-edit>
            <v-row>
              <v-col v-for="(value, key) in item.transport.others" :key="key" cols="6">
                <v-list-item target="_blank" :href="`${TRANSPORT_LINK[value.slug]}/${value.slug}`">
                  <v-list-item-title>{{ value.name }}</v-list-item-title>
                </v-list-item>
              </v-col>
            </v-row>
          </vx-card-list>
        </template>
      </v-col>

      <v-col cols="12" sm="6">
        <vx-card-list title="References" hide-edit :item="item">
          <v-list-item :to="$toList('location', { accountId: item.accountId._id })">
            <v-list-item-title> This host’s locations </v-list-item-title>
            <v-list-item-subtitle> {{ count.accountLocations }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('space', { locationId: this.$route.params.id })">
            <v-list-item-title> All spaces </v-list-item-title>
            <v-list-item-subtitle> {{ count.spaces }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('space', { locationId: this.$route.params.id, status: 0 })">
            <v-list-item-title> Drafted spaces </v-list-item-title>
            <v-list-item-subtitle> {{ count.draftedSpaces }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('space', { locationId: this.$route.params.id, status: 'open' })">
            <v-list-item-title> Open spaces </v-list-item-title>
            <v-list-item-subtitle> {{ count.openSpaces }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('space', { locationId: this.$route.params.id, status: 'closed' })">
            <v-list-item-title> Closed spaces </v-list-item-title>
            <v-list-item-subtitle> {{ count.closedSpaces }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('booking', { locationId: this.$route.params.id })">
            <v-list-item-title> Bookings</v-list-item-title>
            <v-list-item-subtitle>{{ count.bookings || 0 }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('message', { locationId: this.$route.params.id })">
            <v-list-item-title> Messages</v-list-item-title>
            <v-list-item-subtitle>{{ count.messages || 0 }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('review', { locationId: this.$route.params.id })">
            <v-list-item-title> Reviews</v-list-item-title>
            <v-list-item-subtitle>{{ count.reviews || 0 }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('account', { favorite: this.$route.params.id })">
            <v-list-item-title> Favourites</v-list-item-title>
            <v-list-item-subtitle>{{ count.wishlists || 0 }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list title="Availability" :item="item" :tab="1">
          <v-list-item v-for="(availability, index) in item.availabilities" :key="index">
            <v-list-item-title>
              {{ availability.day }}
            </v-list-item-title>

            <v-list-item-subtitle>
              <template v-if="availability.enabled">
                <template v-if="availability.all">24 hour</template>

                <template v-for="(slot, slotIndex) in availability.slots" v-else>
                  <div :key="slotIndex">{{ slot.startHour | hour24 }} - {{ slot.endHour | hour24 }}</div>
                </template>
              </template>

              <template v-else>Closed</template>
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.what3Words?.words" title="What3Words Address" :item="item" :tab="0">
          <v-list-item v-for="(value, key) in item.what3Words" :key="key">
            <v-list-item-title>{{ key | capitalize }}</v-list-item-title>
            <v-list-item-subtitle> {{ value }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.address?.postcode" title="Ideal Postcodes Address" :item="item" :tab="0">
          <template v-for="(value, key) in item.address">
            <v-list-item v-if="value" :key="key">
              <v-list-item-title>{{ key | capitalize }}</v-list-item-title>
              <v-list-item-subtitle> {{ value }} </v-list-item-subtitle>
            </v-list-item>
          </template>
        </vx-card-list>
      </v-col>

      <v-col cols="12">
        <vx-card-json :item="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { TRANSPORT_LINK } from '@tutti/constants';
import { LocationService, CollectionService } from '@tutti/services';

export default {
  data() {
    return {
      TRANSPORT_LINK,
      item: null,
      borough: null,
      count: {},
    };
  },
  async created() {
    await this.getUser();
    this.getBorough();
    this.getCount();
  },
  methods: {
    async getUser() {
      const response = await LocationService.getByIdAdmin(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.name);
      }
    },
    async getCount() {
      const response = await LocationService.count(this.$route.params.id);
      if (response) {
        this.count = response.data;
      }
    },
    async getBorough() {
      if (this.item.transport?.borough) {
        const response = await CollectionService.getBySchemaId({
          collectionId: this.item.transport?.borough,
          schemaId: 'film-services',
        });

        if (response) {
          this.borough = response.data;
        }
      }
    },
  },
};
</script>
